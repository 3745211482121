<template>
  <div>
    <h4>
      <span>Lønnstyper</span>
    </h4>
    <a-collapse accordion>
      <a-collapse-panel :key="i" :header="wageType.name" v-for="(wageType, i) in wageTypes">
        <a-form layout="vertical" @submit.prevent="handleSubmit(i)">
          <div class="row">
            <div class="col-md-4">
              <a-form-item label="Tripletex Number">
                <a-input placeholder="Tripletex Number" v-model="wageType.TripletexNumber"/>
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Navn">
                <a-input placeholder="Navn" v-model="wageType.name"/>
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Type">
                <a-select :options="importTypes" v-model="wageType.type" />
              </a-form-item>
            </div>
          </div>
          <b>Verdier</b>
          <div class="row" v-for="(value, j) in wageType.values" :key="j">
            <div class="col-md-3">
              <a-form-item label="Name">
                <a-input placeholder="Name" v-model="value.name" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Required">
                <a-switch v-model="value.required" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Type">
                <a-select :options="valueTypes" v-model="value.type" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label=" " v-if="!value.standard">
                <a-button type="secondary" @click.prevent="deleteValue(i, j)">
                  <i class="fa fa-trash" ></i>
                </a-button>
              </a-form-item>
            </div>
          </div>
          <div class="col-12">
            <a-form-item>
              <button type="secondary" class="btn btn-light px-5" @click.prevent="addValues(i)">Ny verdi</button>
            </a-form-item>
          </div>
          <a-form-item>
            <button type="primary" class="btn btn-light px-5">Lagre</button>
          </a-form-item>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
    <button type="secondary" class="btn btn-light px-5" @click="addWageType">Opprett ny lønnstype</button>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

const standardValues = [
  {
    name: 'employeeID',
    required: true,
    type: 'string',
    standard: true,
  },
  {
    name: 'department',
    required: true,
    type: 'string',
    standard: true,
  },
  {
    name: 'amount',
    required: true,
    type: 'number',
    standard: true,
  },
  {
    name: 'createdDate',
    required: true,
    type: 'date',
    standard: true,
  },
]

export default {
  data() {
    return {
      wageTypes: [],
      importTypes: [
        {
          value: 'sale',
          label: 'Salg',
        },
        {
          value: 'guaranteed_salary',
          label: 'Garanti',
        },
        {
          value: 'bonus',
          label: 'Bonus',
        },
        // Add more importTypes if needed
      ],
      valueTypes: [
        {
          value: 'number',
          label: 'Number',
        },
        {
          value: 'string',
          label: 'String',
        },
        {
          value: 'date',
          label: 'Date',
        },
      ],
    }
  },
  methods: {
    addWageType() {
      this.wageTypes.push({
        TripletexNumber: '',
        name: '',
        type: '',
        values: standardValues,
        attributes: {},
        new: true,
      })
    },
    addValues(i) {
      const newValue = {
        name: 'Ny Verdi',
        required: false,
        type: '',
      }
      if (this.wageTypes[i].values) {
        this.wageTypes[i].values.push(newValue)
      } else {
        this.wageTypes[i].values = [newValue]
      }
      this.$forceUpdate()
      console.log(this.wageTypes[i])
    },
    deleteValue(i, j) {
      this.wageTypes[i].values.splice(j, 1)
      this.$forceUpdate()
    },
    handleSubmit(i) {
      const wageType = this.wageTypes[i]
      if (wageType.new) {
        wageType.new = false
        firestore.collection('settings').doc('payroll').collection('WageTypeNumber')
          .add(wageType)
          .then(() => {
            openNotification('Lønnstypen lagret')
          })
      } else {
        firestore.collection('settings').doc('payroll').collection('WageTypeNumber')
          .doc(wageType.id)
          .set(wageType)
          .then(() => {
            openNotification('Lønnstypen lagret')
          })
      }
    },
  },
  mounted() {
    firestore.collection('settings').doc('payroll').collection('WageTypeNumber')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const wageType = doc.data()
          wageType.id = doc.id
          wageType.newType = false
          this.wageTypes.push(wageType)
        })
      })
  },
}
</script>
