<template>
  <cui-acl :roles="['underDevelopment']" redirect>
    <div :class="$style.cui__sidebar__inner">
      <h5>
        <strong>Payroll Settings</strong>
      </h5>
      <div class="cui__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }"></div>
      <div :class="$style.cui__sidebar__type" class="mb-4">
        <wage-types />
      </div>
      <div class="cui__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }"></div>
      <div :class="$style.cui__sidebar__type" class="mb-4">
        <h5>
          <strong>Payment Certificate Template</strong>
        </h5>
        <file-upload ref="fileUpload" @onUpload="handleFileUpload" file-path="/contracts/Consultants_Payment_Certificate.docx"></file-upload>
        <a-button @click="handleSubmit">Lagre</a-button>
      </div>
    </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import wageTypes from '@/components/custom/settings/wageTypes'
import fileUpload from '@/components/custom/settings/fileUpload'
import { openNotification } from '@/services/powerAutomate'

export default {
  components: {
    cuiAcl,
    wageTypes,
    fileUpload,
  },
  data() {
    return {
      uploadedFileList: [],
    }
  },
  methods: {
    handleFileUpload(fileList) {
      this.uploadedFileList = fileList
    },
    async handleSubmit() {
      if (this.$refs.fileUpload.fileList.length) {
        await this.$refs.fileUpload.uploadFile()
      }
      openNotification('Payroll Settings', 'Settings saved successfully')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
